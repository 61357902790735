<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Memorial Generator</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select year</label>
              <select class="form-control" v-model="selectedYear">
                <option :value="ys" v-for="ys in years">{{ys}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label><span class="mr-2">Enter periods</span> <a href="#" class="mr-2 text-info" @click.prevent="addAllPeriods" >add all periods</a> <a href="#"  @click.prevent="removeAllPeriods" class="mr-2 text-danger">remove all periods</a> </label>
              <b-form-tags
                  input-id="tags-pills"
                  v-model="periods"
                  tag-variant="primary"
                  tag-pills
                  :tag-validator="periodTagValidator"
                  size="lg"
                  separator=" "
                  placeholder="Enter period and hit enter"
              ></b-form-tags>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Select Grootboeks</label>
              <select class="form-control" @change="onRekSchNumberChanged" v-model="selectedRekSchIndex">
                <option value="-1">-- choose one ---</option>
                <option :value="index" v-for="(rek, index) in rekSchs">
                  {{rek['record'][0]['value']}} - {{rek['record'][2]['value']}} --> ({{rek['selectedConfig']['fieldName']}})
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Selected Grootboeks</label>
              <b-card>
                <b-card-text>
                  <template  v-for="(rekSch, index) in selectedRekSchs">
                    <a href="#" @click.prevent="removeGrootboek(index)">
                      <span class="badge badge-pill badge-soft-primary p-2 mr-2">
                        {{rekSch['record'][0]['value']}} - {{rekSch['record'][2]['value']}} --> ({{rekSch['selectedConfig']['fieldName']}})
                        <i class="fas fa-trash-alt ml-2 mr-1"></i> </span>
                    </a>
                  </template>

                </b-card-text>
              </b-card>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-success" @click="generateMemorialSheet"> Click to generate memorial sheet</button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <template v-if="tokens">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Generated Links  <small class="text-danger">(download links are available to 10 mins for security purposes)</small> </h4>
          <table class="table ">
<!--            <template v-for="(links, token) in tokens">-->
<!--              <tr>-->
<!--                <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>-->
<!--                <td><a :href="getDownloadUrl(token)"  @click="onDownloadLinkTapped">Click to download</a></td>-->
<!--              </tr>-->
<!--            </template>-->
            <template v-for="(links, token) in tokens">
              <template v-if="links[0]['status'] === 'failed'">
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>
                  <td class="text-danger text-bold">{{ links[0]['status'] }}</td>
                  <td>{{ links[0]['status_description'] }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>
                  <td class="text-success">{{ links[0]['status'] }}</td>
                  <td><a :href="getDownloadUrl(token)" @click="onDownloadLinkTapped">Click to download</a></td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import store from "../../../../store/store";

export default {
  name: "generator",
  data(){
    return {
      selectedYear: null,
      periods: [],
      years: [],
      selectedRekSchIndex: -1,
      selectedRekSchs: []
    }
  },
  computed: {
    tokens(){
      return this.$store.getters.GET_UNIT4_DOWNLOAD_TOKENS
    },
    rekSchs(){
     return  this.$store.getters.GET_REK_SCHS;
    }
  },
  methods: {
    generateMemorialSheet(){
      const payload = {
          'year': this.selectedYear,
          'periods': this.periods,
          'grootboeks': this.selectedRekSchs
      }
      this.$store.dispatch('generateMemorialSheet', payload).then(() => {
        this.periods = [];
        this.selectedRekSchs = [];
      })
    },
    addAllPeriods(){
      this.periods = [1,2,3,4,5,6,7,8,9,10,11,12]
    },
    getUni4DownloadTokens(){
      this.$store.dispatch('getUni4DownloadTokens', 'memoriaal')
    },
    getDownloadUrl(token){
      return process.env.VUE_APP_API_BASE_URL + '/download-unit4-file/memoriaal/' + token
    },
    getDownloadDate(createdAt){
      return  moment(createdAt).calendar();
    },
    removeAllPeriods(){
      this.periods = [];
    },
    removeGrootboek(index){
      this.selectedRekSchs.splice(index,1)
    },

    onRekSchNumberChanged(event){
      const index = event.target.value;
      if (index === '-1'){
        return;
      }
      const item = this.rekSchs[index];
      const found = this.selectedRekSchs.find(e => e['recordIndex'] === item['recordIndex'])
      // if(!found){
      //  this.selectedRekSchs.push(item)
      // }
      this.selectedRekSchs.push(item)

      this.selectedRekSchIndex = -1;

    },
    periodTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)){
          return false
        }else if(!Number.isInteger(num)){
          return false
        }else if(num < 1 || num > 12){
          return false
        }else {
          return true;
        }
      }catch (e){
      }

    },
    onDownloadLinkTapped(){
      setTimeout(() => {
        this.getUni4DownloadTokens()
      }, 2000)
    }
  },
  watch: {
    '$store.getters.GET_AVAILABLE_YEARS': function (ys){
      this.years = ys;
      if(this.years.length === 1){
        this.selectedYear = this.years[0]
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Memoriaal')
    this.$store.dispatch('getAvailableYears');
    this.$store.dispatch('getRekSchBySheet', 'memoriaal');
    this.getUni4DownloadTokens()
  },
  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      console.log("OneSignal notification displayed:", event);
      this.getUni4DownloadTokens()
    });
  }
}
</script>

<style scoped>

</style>